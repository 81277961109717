import React, { startTransition, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getAppConfig, useBff } from "../../utils/config"
import { styled } from "@mui/material/styles"
import { Typography, Box, Button, Paper, Divider, TextField, IconButton, InputAdornment, Popper } from "@mui/material"
import { ArrowBack, Download, ModeEdit, Rule, Search, ViewColumn } from "@mui/icons-material"
import Notes from "./Notes/Notes"
import EditExperiment from "./EditExperiment"
import Popup from "../../components/popup"
import AddIcon from "@mui/icons-material/Add"
import AnalysisTable from "./AnalysisTable"
import { useTrovoConfig } from "../../utils/config"
import { useAuth0 } from "@auth0/auth0-react";
import { IExperimentDetails } from "../../types/experiment";
import { demoSamples, demoExperiments } from "../../utils/mock-data";
import Filters from "./Filters"


const PageContainer = styled(Box)({
    display: "flex",
    flexDirection: "column"
})

const ExperimentContent = styled(Box)({
    display: "flex",
    padding: "0 14%",
    gap: 20,
    background: "surface-container-lowest",
    alignItems: "flex-start",
    justifyContent: "space-between"
})

const TopBar = styled("div")({
    width: "100%",
    padding: "8px 2%",
    display: "flex",
    alignItems: "center"
})

const WideCol = styled("div")({
    flex: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "space-between",
    width: "100%"
})
const SmallCol = styled("div")({
    flex: 2,
    display: "flex",
    flexDirection: "column",
    //alignItems: "flex-start",
    alignItems: "space-between",
    gap: 8
})
const ExpFields = styled("div")({
    display: "flex",
    alignItems: "flex-start"
})

const ExpField = styled("div")({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: 10,
    "& :nth-child(2n)": {
        width: "48%",
        whiteSpace: "nowrap"
    },
    "& :nth-child(2n+1)": {
        width: "43%",
        whiteSpace: "nowrap"
    }
})

const BottomContent = styled(Box)({
    padding: "2% 14%"
})

const demoAnalyses = [
    {
        experiment_num: "62",
        analysis_num: "110",
        id: "a0b1c2d3-e4f5-6789-0a1b-2c3d4e5f6789",
        created_time: "2023-09-18T21:48:11.363Z",
        created_by: "440f10bf-0bc4-4492-af23-2453d750a12a",
        ex_id: "b29e2216-8efe-4250-b801-36a3ce28ccde",
        ex_time: "2023-09-18T21:48:11.363Z",
        ex_user: "440f10bf-0bc4-4492-af23-2453d750a12a",
        ex: {},
        name: "",
        description: "",
        pipeline_id: "c61b57bf-0ccd-4e2d-a0e5-dd560fc29672",
        organism: "Homo_sapiens",
        group_options: {
            Individual: [
                "Tibetan-3",
                "Han-1",
                "Tibet-5",
                "Tibetan-4",
                "Han-4",
                "Han-5",
                "Tibetan-2",
                "Han-3",
                "Tibetan-1",
                "Han-2"
            ],
            collection_age_days: ["0.25", "5", "0", "3", "1"],
            collection_age_name: ["6h", "5d", "0h", "3d", "1d"],
            condition: ["hypoxia", "normoxia"],
            country_of_origin: ["Tibet", "China"]
        },
        status: "completed",
        archived: false,
        experiment_id: "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9",
        samples: [
            "0029efef-a143-45ae-a00a-276ab38dcc04",
            "053eb921-cf06-40e6-8307-d6f50b53f95f",
            "082cb0df-8696-4985-aff3-a96443073093",
            "09863467-5fa9-4e4c-91d7-4d2bff60d513",
        ]
    }
]

export function Experiment() {
    let { id } = useParams()
    const navigate = useNavigate()
    const { getAccessTokenSilently } = useAuth0();
    const { apiHost } = getAppConfig();
    const { user } = useTrovoConfig()
    const [data] = useBff("GET", `experiment/${id}/analysis`)
    const [experimentData] = useBff("GET", `experiment/${id}`)
    const [experiment, setExperiment] = useState<IExperimentDetails>(experimentData);
    const [isOpen, setIsOpen] = useState(false)
    const [filters, setFilters] = useState([])
    const [showFilters, setShowFilters]: any = useState(null)
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredList, setFilteredList]:any = useState(null)

    const demoAccount = user.cb_item_price_id?.includes("demo")
console.log(data)
    const handleClose = () => {
        setIsOpen(!isOpen)
        fetchExperiment()
    }

    const fetchExperiment = async () => {
        const response = await fetch(
            `${apiHost}/experiment/${id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                }
            })
        const result = await response.json()
        setExperiment(result)
    }

    const formatDataForAnalysis = (data: any) => {
        return data?.map((d: any) => {

            return {
                analysis_id: d.id,
                title: d.name || d.analysis_num,
                description: d.description,
                time_started: d.created_time,
                time_completed: d.ex_time,
                status: d.status,
                analysis_num: d.analysis_num,
                experiment_id: d.experiment_id
            }
        })
    }

    const formattedData = formatDataForAnalysis(
        demoAccount || id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9" ? demoAnalyses : data
    )

    const headers = [
        { field: "analysis_id", headerName: "Analysis ID" },
        { field: "title", headerName: "Title" },
        { field: "analysis_id", headerName: "Analysis ID" },
        { field: "status", headerName: "Status" }
    ]

    const onSearchChange = (e:any) => {
        setSearchTerm(e.target.value) 
    }
    const formatTime = (utcTimeString: string) => {
        const date = new Date(utcTimeString);
        if (isNaN(date.getTime())) {
            return '';
        }

        const userTimeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
        const formatter = new Intl.DateTimeFormat('en-GB', {
          timeZone: userTimeZone,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true, 
        }) || null;
      
        const formattedParts = formatter?.formatToParts(date) || null;
    
        if (formattedParts) {
            const month = formattedParts?.find((part) => part.type === 'month')?.value || '??';
            const day = formattedParts?.find((part) => part.type === 'day')?.value || '??';
            const year = formattedParts?.find((part) => part.type === 'year')?.value || '??';
            const hour = formattedParts?.find((part) => part.type === 'hour')?.value || '??';
            const minute = formattedParts?.find((part) => part.type === 'minute')?.value || '??';
            const period = formattedParts?.find((part) => part.type === 'dayPeriod')?.value.toLowerCase() || '';
            return `${hour}:${minute}${period} ${month}/${day}/${year}`;
          }
        return null
    }

    useEffect(() => {
        fetchExperiment()
    }, []);


    useEffect(() => {
       searchTerm ? setFilteredList(formattedData?.filter((d:any)=> d?.title?.toLowerCase().includes(searchTerm.toLowerCase()))) : setFilteredList(null) 
    }, [searchTerm])

    console.log(formattedData?.filter((d:any)=> d?.title?.toLowerCase().includes(searchTerm.toLowerCase())))
    return (
        <PageContainer>
            <TopBar sx={{ bgcolor: "surface-container-high", mt: "-3px" }}>
                <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    sx={{ color: "on-surface-container", borderRadius: "50px", px: "2.5%" }}
                    startIcon={<ArrowBack />}
                    onClick={() => {
                        startTransition(() => {
                            navigate("/")
                        })
                    }}>
                    Back to Dashboard
                </Button>
            </TopBar>
            <ExperimentContent sx={{ bgcolor: "surface-container-lowest", pt: "2%" }}>
                <WideCol>
                    <Typography
                        variant="headline"
                        size="large"
                        sx={{ marginBottom: "2%", display: "flex", justifyContent: "space-between" }}>
                        <span data-testid='name'>
                            {demoAccount || id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9"
                                ? demoExperiments[0]?.name
                                : experiment?.name}
                        </span>
                        <span style={{ minWidth: "95px", marginLeft: "8px" }} data-testid='num'>
                            EX-
                            {demoAccount || id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9"
                                ? demoExperiments[0]?.experiment_num
                                : experiment?.experiment_num}
                        </span>
                    </Typography>
                    <Box>
                        <Typography variant="title" size="large" mb={1}>
                            Experiment description
                        </Typography>
                        <Typography variant="body" size="large" mb={4} data-testid='description'>
                            {demoAccount || id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9"
                                ? demoExperiments[0]?.description.split('\n').map((l: any, i: any) => (
                                    <p key={i}>{l}</p>
                                ))
                                : experiment?.description}
                        </Typography>
                    </Box>
                </WideCol>
                <SmallCol>
                    <Box bgcolor={"surface-container-low"} borderRadius={4} p={3} my={1}>
                        <Typography variant="title" size="medium" mb={2}>
                            My Samples ({demoAccount || id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9" ? demoSamples?.length : experiment?.sample_count})
                        </Typography>
                        <Typography variant="body" size="medium" mb={1}>
                            View, add, or manage the samples associated with this experiment.
                        </Typography>
                        <Button
                            variant="outlined"
                            fullWidth
                            sx={{ my: 2, borderRadius: "50px" }}
                            onClick={() => navigate(`/samples/${id}`)}>
                            Manage Samples
                        </Button>
                    </Box>
                </SmallCol>
            </ExperimentContent>
            <ExperimentContent sx={{ bgcolor: "surface-container-lowest", pb: 2 }}>
                <WideCol>
                    <Typography variant="title" size="large" mb={2}>
                        Sample(s) summary
                    </Typography>
                    <ExpFields>
                        <SmallCol sx={{ marginRight: "6%" }}>
                            <ExpField>
                                <Typography variant="title" size="medium">
                                    Organism:
                                </Typography>
                                <Typography variant="body" size="large" data-testid='organism'>
                                    {demoAccount || id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9"
                                        ? demoExperiments[0]?.organism?.split("_").join(" ") +
                                        `${demoExperiments[0]?.organism === "Homo sapien" ? "s" : ""}`
                                        : experiment?.organism?.split("_").join(" ") +
                                        `${experiment?.organism === "Homo sapien" ? "s" : ""}`}
                                </Typography>
                            </ExpField>
                            <ExpField>
                                <Typography variant="title" size="medium">
                                    Analyzed Molecule:
                                </Typography>
                                <Typography variant="body" size="large" data-testid='analyzed_molecule'>
                                    {demoAccount || id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9"
                                        ? demoExperiments[0]?.analyzed_molecule?.split("_").join(" ")
                                        : experiment?.analyzed_molecule?.split("_").join(" ")}
                                </Typography>
                            </ExpField>
                            <ExpField>
                                <Typography variant="title" size="medium">
                                    RNA Selection:
                                </Typography>
                                <Typography variant="body" size="large" data-testid='rna_selection_method'>
                                    {demoAccount || id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9"
                                        ? demoExperiments[0]?.rna_selection_method?.split("_").join(" ")
                                        : experiment?.rna_selection_method?.split("_").join(" ")}
                                </Typography>
                            </ExpField>
                        </SmallCol>
                        <SmallCol sx={{ marginLeft: "3%" }}>
                            <ExpField>
                                <Typography variant="title" size="medium">
                                    Adaptor for Sequencing:
                                </Typography>
                                <Typography variant="body" size="large" data-testid='sequencing_adapter'>
                                    {demoAccount || id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9"
                                        ? demoExperiments[0]?.sequencing_adapter?.split("_").join(" ")
                                        : experiment?.sequencing_adapter?.split("_").join(" ")}
                                </Typography>
                            </ExpField>
                            <ExpField>
                                <Typography variant="title" size="medium">
                                    Sequencing Platform:
                                </Typography>
                                <Typography variant="body" size="large" data-testid='sequencing_platform'>
                                    {demoAccount || id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9"
                                        ? demoExperiments[0]?.sequencing_platform?.split("_").join(" ")
                                        : experiment?.sequencing_platform?.split("_").join(" ")}
                                </Typography>
                            </ExpField>
                            <ExpField>
                                <Typography variant="title" size="medium">
                                    Platform Model:
                                </Typography>
                                <Typography variant="body" size="large" data-testid='platform_model'>
                                    {demoAccount || id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9"
                                        ? demoExperiments[0]?.platform_model?.split("_").join(" ")
                                        : experiment?.platform_model?.split("_").join(" ")}
                                </Typography>
                            </ExpField>
                        </SmallCol>
                    </ExpFields>
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<ModeEdit fontSize="small" />}
                        sx={{ alignSelf: "flex-end", height: "33px", mt: 4 }}
                        onClick={() => setIsOpen(true)}>
                        <Typography variant="body" size="large">
                            edit
                        </Typography>
                    </Button>
                </WideCol>
            </ExperimentContent>
            <BottomContent>
                <Paper
                    sx={{
                        padding: "1.5% 3%",
                        borderRadius: "12px",
                        bgcolor: "surface-container-lowest",
                        mb: 5,
                        pb: 3,
                        minWidth:'920px'
                    }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", }} py={1}>
                        <Typography variant="headline" size="small">
                            My Analyses
                        </Typography>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            sx={{ height: "36px", borderRadius: "50px", px: 5 }}
                            onClick={() => navigate(`/experiment/${id}/analysis/draft/`)}>
                            Create Analysis
                        </Button>
                    </Box>
                    <Divider />
                    <Box >
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            sx={{ '& input':{ height: '19px'}, my:1}}
                            onChange={onSearchChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" >
                                        <IconButton >
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <IconButton color="primary" aria-label="filter"
                            onClick={(e) => setShowFilters(showFilters ? null : e.currentTarget)}
                            sx={{ border: '1px solid', borderRadius: '5px', borderColor: 'outline-variant', mx: 1, my:1, height:'35px', bgcolor: showFilters ? 'surface-container-highest' : '' }}>
                            <Rule />
                            <Typography variant='label' size='large' mx={1}> Filter </Typography>
                        </IconButton>
                        {/* These two buttons were added in May 2024 but never connected to anything. Disabling for now.
                       <span style={{ marginLeft:'53%' }} >
                            <ViewColumn sx={{ fontSize:'25px' }} /> 
                            <Download sx={{ ml:'3px', fontSize:'25px' }} /> 
                        </span>
                        */}
                    </Box>
                    <Popper
                open={showFilters !== null}
                anchorEl={showFilters}
                >
                 <Filters initialList={formattedData} setFilteredList={setFilteredList} filteredList={filteredList} setShowFilters={setShowFilters} /></Popper>
                
                    <AnalysisTable recentAnalyses={filteredList !== null ? filteredList : formattedData} headers={headers} formatTime={formatTime} />
                </Paper>
                <Notes experimentId={id} formatTime={formatTime} />
            </BottomContent>
            {experiment && (
                <Popup isOpen={isOpen} onClose={handleClose}>
                    <EditExperiment data={demoAccount || id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9" ? demoExperiments[0] : experiment} onClose={handleClose} />
                </Popup>
            )}
        </PageContainer>
    )
}
